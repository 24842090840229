const development_url = "http://localhost:5000"; // 本地调试地址
const dev_websocket_url = "ws://localhost:8084/websocket/init"; // 本地调试地址
// const development_url = "http://oaapi.king90.com"; // 本地调试地址
// const dev_websocket_url = "ws://oaapi.king90.com/oa/websocket/init"; // 本地调试地址
const file_development_url = "http://localhost:5000/share/fileuploader"; // 本地调试地址
// const file_development_url = "http://oaapi.king90.com/share/fileuploader"; // 本地调试地址

const production_url = "http://oaapi.king90.com"; // 测试域名
// const production_url = "http://oaapi.hbsjykxyjy.cn"; // 正式域名
const file_production_url = "http://oaapi.king90.com/share/fileuploader"; // 测试域名
// const file_production_url = "http://oaapi.hbsjykxyjy.cn/share/fileuploader"; // 正式域名

const applicationId = "ebed0963-8212-4d7c-8150-aea31a5a55de";
export default {
    development_url,
    baseUrl: (process.env.NODE_ENV === "development" ? development_url : production_url), //接口地址
    fileServerUrl: (process.env.NODE_ENV === "development" ? file_development_url : file_production_url),
    wpsAppId: (process.env.NODE_ENV !== "development" && production_url.indexOf("hbsjykxyjy") != -1) ? "SX20240205PPNGJH" : "SX20240407JUZBZA",
    appKey: (process.env.NODE_ENV !== "development" && production_url.indexOf("hbsjykxyjy") != -1) ? "oa" : "oatest",
    websocketUrl: (process.env.NODE_ENV === "development" ? dev_websocket_url : production_url.replace("http", "ws") + "/oa/websocket/init"),
    headerMenu: true,
    appName: "河北省教育科学研究院<br/>单位内部管理平台",
    shortName: "单位内部管理平台",
    remoteType: "admin",
    applicationId: applicationId,
    audit: true, // 文章是否需要审核
    editorConfig: {
        toolbars: [[
            'source', 'undo', 'redo', '|',
            'bold', 'italic', 'underline', 'fontborder', 'strikethrough',
            'superscript', 'subscript', 'removeformat', 'formatmatch', '|',
            'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', '|',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
            'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
            'indent', 'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
            'touppercase', 'tolowercase', '|', 'link', 'unlink', '|',
            'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
            'simpleupload', 'insertimage', 'insertvideo', 'music', 'attachment',
            'horizontal', 'spechars', '|',
            'inserttable', 'insertparagraphbeforetable', 'insertrow',
            'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright',
            'mergedown', 'splittocells', 'splittorows', 'splittocols', '|',
            'searchreplace'
        ]]
    }
}
